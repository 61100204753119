import { resetPassword } from 'aws-amplify/auth'
import Link from 'next/link'
import CommonEmailForm from '../../common/EmailForm/email-form'
import notifyWithContext from '../../../../utils/cognito/notify-with-context'

const intro = (
  <p>
    Reset your password.
  </p>
)
const generalError = (
  <div>
    We are unable to reset your email. Please check your email has been entered correctly.
    <br />
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const EmailForm = ({ onConfirmResetPassword }: { onConfirmResetPassword: () => void }) => {
  const submitEmail = async (email: string) => {
    sessionStorage.setItem('email', email)

    try {
      const result = await resetPassword({ username: email })
      switch (result.nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
          onConfirmResetPassword()
          return []
        default:
          await notifyWithContext({
            message: 'An unexpected response from Cognito occurred when resetting the password. The method that caused this error is resetPassword.',
            response: result,
            cognitoMethod: 'resetPassword',
          })
          return [generalError]
      }
    } catch (error) {
      if (error instanceof Error && error?.name === 'LimitExceededException') {
        return ['You have tried to reset your password too many times. Please wait 1 hour before trying again.']
      }
      await notifyWithContext({
        error,
        message: 'An unexpected error from Cognito occurred when resetting the password. The method that caused this error is resetPassword.',
        cognitoMethod: 'resetPassword',
      })
      return [generalError]
    }
  }

  return (
    <CommonEmailForm submitEmail={submitEmail} heading="Reset password" intro={intro} />
  )
}

export default EmailForm
